.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs {
  width: 12em;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs .tab {
  width: 100%;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-content {
  padding: 0;
  background-color: #ffffff;
}

.alerts-page {
  display: flex;
  margin-top: 7%;
}

@media only screen and (min-width: 800px) {
  .alerts-page {
    display: block;
    margin-top: 3%;
  }
}
