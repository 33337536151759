[type='reset'],
[type='submit'],
button,
html,
[type='button'] {
  -webkit-appearance: none;
}

ButtonToolBar {
  padding: 10px;
}

.backlog-graph-title {
  display: inline-block;
}

h3.report-title {
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
}

.out-of-stock {
  height: 40vh;
  margin-bottom: 2%;
}

.card-body h5.historical-graph-title {
  font-weight: bold;
}

.fade {
  opacity: 100% !important;
}

.content_holder {
  margin-left: 7%;
  margin-right: 7%;
  background-color: white;
  margin: 1em;
  margin-top: 2%;
}

.tabs:active,
.tabs.active {
  color: white;
  background-color: #003f73;
  box-shadow: 0.00675rem 2.5rem -0.15625rem #343434;
  border-radius: 0.15625rem 0 0 0.15625rem;
}

.backlog-tabs {
  visibility: hidden;
}

.tabs {
  color: white;
  background-color: #166ed5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #46d2ff;
  border-radius: 0.15625rem 0 0 0.15625rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.tabs2 {
  border-radius: 0 0.15625rem 0.15625rem 0;
}

.tabs2:active,
.tabs2.active {
  border-radius: 0 0.15625rem 0.15625rem 0;
}

.backlog-toggle {
  margin: 0 auto;
  padding-bottom: 3%;
}

.backlog-graphs {
  display: flex;
}

.departments-links {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.departments-links-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #003f73 !important;
  /*find better way to do this*/
  color: #ffffff;
  padding-left: 6%;
  padding-right: 6%;
}

.department-name {
  padding-left: 6%;
  width: 100%;
}

.department-orders {
  padding-right: 8.5%;
}

.departments-links-detail {
  display: flex;
  flex-direction: column;
}

.department-link-row {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  /* margin-bottom: .1rem; */
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 1%;
  padding-bottom: 1%;
}

.department-link-row > .department-orders {
  width: 15vw;
}

.backlog-graph-body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.historical-graph-body {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2%;
}

[type='radio'] {
  display: none;
}

[type='radio']:checked {
}

@media only screen and (min-width: 768px) {
  h3.report-title {
    margin-top: 7%;
  }
  .order-backlog-body {
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
  }
  .backlog-graph-body {
    flex-basis: 50%;
  }
  .departments-links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;
    flex-grow: 1;
  }
  .department-tables {
    display: flex;
  }
  .department-link-row > .department-orders {
    text-align: center;
  }
  .tabs {
    box-shadow: 0 0.125rem 0.5rem -0.25rem #46d2ff;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .tabs:active,
  .tabs.active {
    box-shadow: 0 2px 8px -5px #343434;
  }
}
