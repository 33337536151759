.buttonbar {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 1em 0em;
  margin-top: 1em;
}

.forms-label {
  font-weight: bold;
  display: inline-block;
}

.input-error {
  border: 1px solid red;
}

.modal-break-tag {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

#footer-container {
  width: 100%;
}

#footer-actions {
  width: 100%;
  display: inline-flex;
}

#footer-buttons {
  width: 100%;
  text-align: right;
}

#modal-remove-container {
  width: 50%;
  display: inline-block;
}

#level-modal-remove {
  color: #007aff;
  font-weight: normal;
  font-size: medium;
  text-align: left;
  padding-left: 0.5em;
  margin-bottom: 1em;
}

#level-modal-add {
  color: #007aff;
  font-weight: normal;
  font-size: medium;
  display: inline-block;
  width: 50%;
  text-align: right;
}

.submission-report-alerts > h4 {
  text-align: left;
  padding-bottom: 0.5em;
}

.submission-report-alerts > p {
  text-align: left;
  margin-bottom: 0em;
}

.list-view-headers {
  font-weight: bold;
}

.resources-display {
  font-size: 0.7em;
  color: #979799;
  margin-left: 0.4em;
  font-weight: 500;
}

.optionalText {
  display: inline-block;
}

hr.styled-nav {
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -15px rgba(0, 0, 0, 0.5);
}

.resources-display {
  font-size: 0.7em;
  color: #979799;
  margin-left: 0.4em;
  font-weight: 500;
}

.navbar {
  padding: 1;
}

.levels-navbar {
  margin-top: 0.5em;
}

.levels-navbar > .navbar {
  margin-bottom: 3em;
  box-shadow: 0px 0.5px 0px 0px;
  justify-content: flex-start;
  margin-left: 0.5em;
  padding-left: 0;
}

.not-active-toggler {
  background-image: url('../../img/down_caret.png');
  background-size: 100% 100%;
  height: 0.8rem;
}

.active-toggler {
  background-image: url('../../img/up_caret.png');
  background-size: 100% 100%;
  height: 0.8rem;
}

nav > button.default-button {
  width: 100%;
  font-size: 0.7em;
}

.level-view-header {
  padding-left: 0em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  font-weight: bold;
  display: inline-block;
}

.level-view-header > p {
  display: inline;
  margin-left: 1em;
}

.level-view-active {
  display: inline-block;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  padding-left: 1em;
  margin-bottom: 0.7em;
  background: #fafafa;
}

.level-view-items {
  display: inline-block;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  padding-left: 1em;
  margin-bottom: 0.7em;
}

#level-row-title {
  display: inline-block;
  width: 65%;
}

.level-icon-buttons {
  display: inline-block;
}

.icon-button {
  color: #166ed5;
}

.tabs:active,
.tabs.active {
  color: white;
  background-color: #003f73;
  box-shadow: 0.00675rem 2.5rem -0.15625rem #343434;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
}

.backlog-tabs {
  visibility: hidden;
}

.tabs {
  color: white;
  background-color: #166ed5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #46d2ff;
  border-radius: 0.15625rem 0.15625rem 015625rem 0.15625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.backlog-toggle {
  margin: 0 auto;
  padding-bottom: 3%;
}

.manage-infrastructure-header {
  text-align: right;
  padding-bottom: 2em;
  padding-top: 1em;
}

@media only screen and (min-width: 768px) {
  .levels-navbar > .navbar {
    justify-content: flex-start;
    margin-bottom: 2em;
    box-shadow: 0px 0.1px 0px 0px;
    margin-left: 1.4em;
  }
  .navbar {
    padding: 0.5rem 1rem;
  }
  nav > button.default-button {
    width: auto;
    margin-left: auto;
    margin-right: 1%;
    font-size: 0.8em;
  }
  #level-row-title {
    width: 80%;
  }
  .level-view-header {
    padding-left: 1em;
  }
  .level-view-items {
    padding-left: 3em;
  }
  .level-view-active {
    padding-left: 3em;
  }
  .tabs {
    box-shadow: 0 0.125rem 0.5rem -0.25rem #46d2ff;
    border-radius: 2.8125rem 2.8125rem 2.8125rem 2.8125rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .tabs:active,
  .tabs.active {
    box-shadow: 0 2px 8px -5px #343434;
    border-radius: 2.8125rem 2.8125rem 2.8125rem 2.8125rem;
  }
}
