.grid_view_container {
  height: 510px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 1%;
  padding-right: 1%;
  /* Increase/decrease this value for cross-browser compatibility */
  /* box-sizing: content-box; */
  /* So the width will be 100% + 17px */
}

/* Chrome 29+ */

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .grid_view_container {
    height: 510px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 1%;
    padding-right: 1%;
    /* Increase/decrease this value for cross-browser compatibility */
    /* box-sizing: content-box; */
    /* So the width will be 100% + 17px */
  }

  .dropdownmenu {
    padding: 0;
  }
}

.filter_group {
  align-items: center;
  height: 50px;
  margin-left: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.grid_search_bar {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 2%;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  width: 96%;
  height: 40px;
}
