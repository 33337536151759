.input-text-enabled {
  text-align: left;
  color: black;
}

.input-text-disabled {
  text-align: left;
  color: gray;
}

.md-form {
  font-weight: bold;
}

.user-profile-title {
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  color: #343434;
  margin-bottom: 6%;
}

.profile-form-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 0.3em;
}

.change-password {
  color: #166ed5;
  margin-top: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.change-password-title {
  text-align: left;
  padding-bottom: 0em;
  font-weight: bold;
}

.change-password-header {
  display: inline-block;
}

.input-password {
  text-align: center;
  color: black;
}

.input-password:focus {
  color: black;
}

.change-password-btn {
  width: 40%;
}

.change-password-alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.change-password-alerts > h4 {
  text-align: left;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.change-password-alerts > p {
  margin-bottom: 2px;
  padding-top: 3px;
}

.profile-card {
  background-color: #fafafa;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.5rem;
}

input:disabled {
  border-bottom: none !important;
}

input[type='file'] {
  border: none;
}

#givenNameInput > .md-form {
  display: inline-block;
}

.responsive-container {
  margin: 0 auto;
  background-color: white;
}

.responsive-container img {
  border-radius: 0.25rem;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .responsive-container {
    min-width: 60%;
    /* cap container min width */
    max-width: 60%;
    /* cap container max width */
    position: relative;
    overflow: hidden;
  }
  .responsive-container:before {
    display: block;
  }
  /* .md-form:first-child, .md-form:first-child + .md-form {
        display: inline-block;
        margin-bottom: 0;
    } */
  .responsive-container img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* add browser-prefixes */
  }
  .user-profile-title {
    font-size: 1.8em;
  }
  .profile-card {
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 3rem;
    border: none;
    border-radius: unset;
    padding-top: 0;
    margin-top: 7%;
  }
}
