.card_container {
  vertical-align: top;
  padding: 0.5%;
  width: 23%;
  min-width: 23%;
  height: 30%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 1% 0.7% 0.5% 1%;
  border-radius: 4px;
  color: black;
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

.order_container {
  height: 35%;
}

.order_status {
  color: #f74446;
  padding-left: 2.5%;
  font-style: italic;
  font-weight: 1115;
  margin-bottom: 2%;
  font-size: 12px;
}

.card_container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: black;
}

.card_container img {
  position: relative;
  max-width: 60px;
  max-height: 60px;
  top: 4%;
  bottom: 0;
  left: 2%;
  right: 0;
}

.top {
  display: flex;
  flex-direction: row;
}

.details {
  margin-left: 5px;
  width: 100%;
}

.name {
  display: flex;
  min-width: 80%;
  text-align: center;
  word-break: break-all;
  padding: 3%;
  height: auto;
  width: auto;
  justify-content: center;
  margin-bottom: 5px;
  font-family: helvetica;
  font-weight: bolder;
  font-size: 1em;
}

.justify_center {
  justify-content: center;
}

.velocity_rate {
  margin-left: 0.2em;
  font-family: helvetica;
  font-weight: bold;
  font-size: 0.9em;
  overflow: hidden;
}

.label {
  font-weight: 200;
  font-size: 0.9em;

  text-align: center;
}
.empty_label {
  min-height: 20px;
}

.min_width_label {
  min-width: 30px;
}

.horizontal_line {
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: 0.3rem;
}

.horizontal_line2 {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.bottom {
  margin-right: 0.5em;
  width: 100%;
  font-size: 0.65em;
  font-family: helvetica;
  padding-right: 10px;
  padding-left: 10px;
}

.last_seen {
  align-self: flex-start;
  text-align: center;
  margin-left: 0.1em;
}

.last_seen_title {
  font-weight: 200;
}

.last_seen_data {
  font-weight: bold;
}

.last_updated {
  align-self: flex-start;
  margin-left: auto;
  font-weight: 200;
  margin-right: 0.1em;
  text-align: center;
}

.last_updated_title {
}

.last_updated_time {
  font-weight: 600;
}

.last_updated_date {
  font-weight: 600;
}

.creation_time {
  font-weight: 600;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}
