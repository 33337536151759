.clear-button {
  padding: 3px;
  margin-top: 35px;
  margin-left: auto;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.clear-button:hover {
  cursor: pointer;
  background-color: lightgrey;
}
.input-area {
  width: 240%;
}

.group-input-area-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-label {
  width: 240%;
}

.image-style {
  width: 10px;
  vertical-align: baseline;
}
