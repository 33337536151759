header {
  height: 4.5em;
}

#header-logo {
  margin-right: 0.6rem;
  padding-left: 0.5em;
}

#main-navbar {
  padding-top: 5px;
  padding-bottom: 5px;
}

@media only screen and (min-width: 768px) {
  #header-logo {
    padding-left: 0;
  }
}
