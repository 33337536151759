p {
  margin-bottom: 0;
}

.card_container {
  vertical-align: top;
  padding: 0.5%;
  width: 23%;
  min-width: 30%;
  height: 30%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 1% 0.7% 0.5% 1%;
  border-radius: 4px;
  color: black;
  cursor: pointer;
}

.card_image {
  border-radius: 0.25rem !important;
}

.asset_container {
  height: 36%;
}

.asset_status {
  color: #f74446;
  padding-left: 2.5%;
  font-style: italic;
  font-weight: 1115;
  margin-bottom: 2%;
  font-size: 12px;
}

.card_container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: black;
}

.name {
  display: flex;
  min-width: 80%;
  height: 50px;
  text-align: center;
  word-break: break-all;
  padding: 3%;
}

.red_font {
  color: red;
}

.card_container img {
  position: relative;
  max-width: 60px;
  max-height: 60px;
  top: 4%;
  bottom: 0;
  left: 2%;
  right: 0;
}

.float_left {
  float: left;
}

.top {
  display: flex;
  flex-direction: row;
}

.details {
  margin-left: 5px;
  width: 100%;
}

.name {
  height: auto;
  width: auto;
  justify-content: center;
  font-family: helvetica;
  font-weight: bolder;
  font-size: 1em;
  margin-bottom: 0;
}

.kpiContainer {
  margin-left: 9%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.card_details {
  width: 100%;
  position: relative;
  align-items: center;
}

.count {
  margin-right: 15px;
  margin-left: 5px;
  font-family: helvetica;
  font-weight: bolder;
  font-size: 0.9em;
}

.missing {
}

.tracker_serial {
  font-family: helvetica;
  font-weight: bold;
  font-size: 0.9em;
  overflow: hidden;
}

.label {
  font-weight: 200;
  font-size: 0.9em;
}

.min_width_label {
  min-width: 30px;
}

.horizontal_line {
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: 0.3rem;
}

.horizontal_line2 {
  width: 100%;
  margin-bottom: 0.3rem;
  margin-top: 1rem;
}

.bottom {
  margin-right: 0.5em;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  font-family: helvetica;
  font-size: 0.8em;
  text-align: center;
}

.bottom_title {
  font-weight: 200;
  margin-right: 0.1em;
}

.bottom_data {
  margin-left: 0.1em;
  font-weight: bold;
}

.bottom_left {
  align-self: flex-start;
}

.bottom_right {
  margin-left: auto;
}

.bottom_center {
  margin-left: auto;
}

.lastupdated_overall {
  display: flex;
  float: right;
  height: 150%;
  align-self: flex-end;
  font-size: 0.9em;
  font-family: helvetica;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}
