.card-container {
  vertical-align: top;
  padding: 0.5%;
  width: 23%;
  height: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  display: inline-flex;
  position: relative;
  margin: 1% 0.7% 0.5% 1%;
  border-radius: 10px;
  color: black;
  cursor: pointer;
}

.card-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: black;
}

.name {
  display: flex;
  min-width: 80%;
  height: 50px;
  text-align: center;
  word-break: break-all;
  padding: 3%;
  font-family: 'Roboto', 'Lucida Grande', 'Lucida', sans-serif;
  font-weight: 450
}

.card-container img {
  max-width: 60px;
  max-height: 60px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}