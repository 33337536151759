@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.content-body {
  height: 100vh;
  padding-top: 4rem;
  margin-top: 0;
}

.content-body.login-page-body {
  background-image: url('../../logo/Banner.png');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.login-form {
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 460px;
}

.form-group {
  padding-bottom: 0.3em;
}
.login-form-background {
  background-color: rgba(25, 25, 25, 0.9);
  padding: 3em;
  border-radius: 0.5em;
  margin-top: -3.3em;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.9);
}
.login-form > section {
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
}

.login-page-body .md-form {
  font-weight: normal;
}

.login-form a {
  color: #fff;
}

.login-form img {
  display: block;
  margin: 0 auto;
  margin-bottom: 55px;
}

.form-control + .glyphicon {
  position: absolute;
  left: 0;
  top: 14%;
  padding: 8px 0 0 17px;
  font-size: 15px;
  color: #757575;
}

.login-form label {
  color: #ffffff;
}

.input-default {
  text-align: center;
  color: white;
  background-color: transparent;
}

.input-default:focus {
  color: white;
  background-color: transparent;
}

.btn-block {
  padding: 2vh;
}

.brand-logo {
  height: 15rem;
}

.center-align {
  text-align: center;
}

.asText {
  background: none;
  border: none;
  margin-top: 15px;
  padding: 0;
  color: white;
  cursor: pointer;
}

.change-password-button {
  text-transform: none;
  letter-spacing: 0.04rem;
  margin-left: 0.5em;
  margin-right: 1em;
}
