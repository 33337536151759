#object-page-title {
  font-weight: bold;
}

h4#object-page-title {
  padding-bottom: 2%;
  padding-top: 5%;
  padding-left: 9%;
}

h4#object-page-title {
  text-align: left;
  align-items: center;
  flex-direction: column;
  display: flex;
}

#expedite-text {
  font-style: italic;
}

.title-group {
  display: inline-block;
  margin: 20px;
}

.icon-description-section {
  border: none;
  font-size: 16px;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-around;
}

.icon-description-section > .icon-section {
  padding-right: 2rem;
  font-weight: 500;
  text-align: right;
  width: 100%;
  font-weight: bold;
}

.icon-description-section > .description-section {
  flex-basis: 125%;
  font-weight: 200;
}

.icon-description-section > .description-section > .expedite-group {
  display: flex;
  align-items: center;
}

.icon-description-section > .description-section > .expedite-group > span {
  padding-left: 1rem;
}

.outer-card-styling {
  background-color: #fafafa;
  margin: 5em 1em 1em 1em;
}

.card.card-styling {
  background-color: #fafafa;
  display: -ms-flexbox;
  display: flex;
}

.details-card-responsive-container {
  margin: 0 auto;
  background-color: white;
}

.details-card-responsive-container img {
  border-radius: 0.25rem;
  width: 100%;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.itlaics-text {
  font-style: italic;
}

.bold-text {
  font-weight: 800;
}

.light-text {
  font-weight: 200;
}

.last-seen-info > * {
  margin-right: 0.2em;
}

.last-updated-time {
  min-width: 180px;
}

.dropdown-item {
  outline: none;
}

.dropdown-label {
  margin-bottom: 0em;
}

.delete-label {
  color: #F72227;
  margin-bottom: 0em;
}

.status-container {
  justify-content: center;
  font-style: italic;
  padding: 1%;
  margin-top: -1rem;
}

.status-value {
  margin-left: 0.2em;
}

.delete-object-alerts{
  margin-bottom: 0em;
}

@media only screen and (min-width: 768px) {
  .cardbody-styling {
    width: 0;
    flex-grow: 1;
  }

  .last-seen-info {
    display: flex;
    flex-direction: row;
  }


  .details-card-responsive-container {
    min-width: 40%;
    /* cap container min width */
    max-width: 40%;
    /* cap container max width */
    position: relative;
    overflow: hidden;
  }
  .details-card-responsive-container:before {
    display: block;
  }
  /* .md-form:first-child, .md-form:first-child + .md-form {
        display: inline-block;
        margin-bottom: 0;
    } */
  .details-card-responsive-container img {
    width: auto;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* add browser-prefixes */
  }
  h4#object-page-title {
    padding-bottom: 4%;
    padding-top: 2%;
    padding-left: 9%;
  }
  #object-page-title {
    font-size: x-large;
  }
  .card.card-styling {
    -ms-flex-direction: row;
    flex-direction: row;
    border: none;
    border-radius: unset;
  }
  .card img {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .view.Ripple-parent {
    flex-basis: 65%;
    display: flex;
    align-items: center;
  }
  .icon-description-section {
    border-bottom: 0.05rem solid #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    border: none;
    margin-bottom: 0.5rem;
  }
  .icon-section > .fa-2x {
    font-size: 3.2em;
  }
  .icon-description-section > .icon-section {
    padding: 0 1rem;
    padding-right: 3em;
    /* flex-basis: 50%; */
    text-align: right;
  }
  .object-page-items {
    padding-left: 15%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .object-page-items {
    padding-left: 0%;
    padding-right: 0%;
  }
  .icon-description-section > .icon-section {
    padding-right: 10%;
  }
}

.edit-box {
  width: auto;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.edit-box:focus {
  color: #495057;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
