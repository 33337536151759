.tabbedSectionComponent-container {
  display: flex;
  flex-direction: row;
  padding: 1em;
  min-height: 25em;
}

.tabbedSectionComponent-container .clickable {
  cursor: pointer;
}

.tabbedSectionComponent-container .clickable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tabbedSectionComponent-container .tabbedSectionComponent-tabs {
  margin: 1em 0 1em 0;
  display: flex;
  flex-direction: column;
}

.tabbedSectionComponent-container .tabbedSectionComponent-tabs > .tab {
  flex-basis: 100%;
  padding: 0.5em;
  border-radius: 0.2em;
  text-align: center;
  display: flex;
  width: 100%;
  max-height: 2.3em;
  align-items: center;
}

.tabbedSectionComponent-container .tabbedSectionComponent-tabs > .tab.active {
  background-color: #166ed5;
  color: white;
}

.tabbedSectionComponent-container .tabbedSectionComponent-content {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin: 1em 1em 1em 0.5em;
  padding: 1em;
  width: 100%;
}
