.menu_title,
.selectable_menu_title
.selectable_menu_title_selected {
  margin-top: 2%;
  padding-left: 4%;
  margin-bottom: 10px;
  font-size: 1rem;
  color: gray;
}

.selectable_menu_title,
.selectable_menu_title_selected {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: black;
  background-color: white;
  width: 90%;
  text-align: left;
}

.selectable_menu_title_selected {
  background-color: #166ed5;
  color: white;
}

.selectable_menu_title:hover {
  background-color: #efefef;
}

.vertical_tag {
  flex-direction: column;
  display: flex;
}

.filter_tab_style,
.filter_tab_style_selected {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 20%;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: Black;
  font-size: 0.9em;
  margin-top: 2%;
  width: 90%;
}

.filter_tab_style_selected {
  background-color: #166ed5;
  color: white;
}

.filter_tab_style:hover {
  background-color: #efefef;
}

.area {
  margin-left: 10px;
  margin-right: 10px;
  width: 12%;
  min-width: 100px;
  float: left;
  height: 500px;
  border-right: 2px #eee solid;
  margin-top: 1%;
}
