#addTracker {
  font-size: 1.4em;
  color: #166ED5;
}

.success {
  background-color: #166ED5;
}

#remove {
  font-size: 10pt;
  color: #166ED5;
  text-align: left;
  width: 50%;
}

#Title {
  font-size: 25pt;
  font-weight: bold;
  display: flex;
  font-family: 'Nunito', sans-serif;
}

.ag-theme-balham {
  display: table;
  margin-left: 5%;
  margin-right: 3%;
  margin-bottom: 2%;
}

.qty {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

#content {
  display: flex;
  justify-content: left;
}

#trackers {
  font-size: 20pt;
}

#inside1 {
  width: 100%;
  border-radius: 5px black;
}

.box1 {
  display: flex;
  justify-content: space-between;
}

h5 {
  font-size: 12pt;
}

.inputStuff {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.inputStuffWithBottomMargin {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 20px;
}

#modal-add {
  width: 50%;
  font-size: 10pt;
  text-align: left;
  color: #166ED5;
  border: none;
  cursor: pointer;
}

.header {
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
}

.subheading {
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
}

.manage-plant-btn {
  border: none;
}

.input-error {
  border: 1px solid red;
}

.actions-bar>button.default-button {
  width: auto;
  margin-left: auto;
  font-size: .8em;
  margin-right: .2em;
  margin-top: .1em;
}

.actions-bar>button.delete-button:hover {
  background-color: red;
  color: white;
}

.actions-bar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

h4.table-name {
  font-weight: bold;
}