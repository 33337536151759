.content_holder {
  height: auto;
  width: auto;
  background-color: white;
  vertical-align: top;
  margin-top: 4.5%;
}

.override-padding {
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin: 0;
  height: auto;
}

.chart {
  display: inline-block;
  vertical-align: top;
  margin-top: 2%;
  padding: 0 0 0 10%;
}

.overview {
  display: inline-block;
  width: 50%;
  height: 40%;
  margin-left: 10%;
  padding: 2% 5% 0 0;
  align-content: center;
}

.line {
  position: static;
  background-color: #eee;
  border: 0 none;
  color: #eee;
  height: 2px;
  margin: 0 auto;
  width: 100%;
  top: 46%;
  right: 50;
  left: 0;
  margin-bottom: 1%;
}

.grid-view {
  height: 100%;
  width: 100%;
  display: inline-block;
}

@media only screen and (max-width: 1380px) {
  .content_holder {
    margin-top: 8%;
  }
  .chart {
    position: block;
    width: 100%;
    padding: 0;
  }
  .overview {
    position: block;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .content_holder {
    margin-top: 15%;
  }
}